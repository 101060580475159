<template>
  <button>
    <slot />
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    msg: String
  }
}
</script>

<style lang="scss" scoped>
button {
  color: #ffffff;
  background: #08a742;
  font-weight: 700;
  width: 100%;
  padding: 11px 20px;
  transition: all 0.25s ease-in-out;
  border: 0;
  border-radius: 2px;
  cursor: pointer;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 1.4em;

  &:hover {
    background: #26b259;
  }
  &:focus,
  &:active {
    background: #0d923e;
  }
}
</style>
